/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("@fontsource/montserrat/latin-200.css") // Defaults to weight 400.
require("@fontsource/montserrat/latin-300.css") // Defaults to weight 400.
require("@fontsource/montserrat/latin-400.css") // Defaults to weight 400.
require("@fontsource/montserrat/latin-500.css") // Defaults to weight 400.
require("@fontsource/montserrat/latin-600.css") // Defaults to weight 400.
require("@fontsource/montserrat/latin-700.css") // Defaults to weight 400.