// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-frequently-asked-questions-js": () => import("./../../../src/templates/frequently-asked-questions.js" /* webpackChunkName: "component---src-templates-frequently-asked-questions-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-privacy-pages-js": () => import("./../../../src/templates/privacy-pages.js" /* webpackChunkName: "component---src-templates-privacy-pages-js" */)
}

